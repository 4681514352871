import { FC } from 'react';
import { Flex, Text, Heading as HeadingChakra } from '@chakra-ui/react';
import { ExternalLinks } from '@/constants';
import { AppLink } from '@/components/common';

const Heading: FC = () => (
  <Flex flexDirection="column" alignItems="center">
    <HeadingChakra as="h1" variant="main" fontWeight="700" textAlign="center" my="20px" mx="0">
      Ace the SQL & Data Science Interview
    </HeadingChakra>
    <Text fontFamily="KumbhSans" maxW="570px" textAlign="center" mb="12px">
      Practice SQL Interview and Data Science Interview questions on DataLemur. Made by Nick Singh, Best-Selling Author
      of{' '}
      <AppLink
        href={ExternalLinks.BookLink}
        style={{ textDecoration: 'underline' }}
        target="_blank"
        rel="noopener noreferrer"
      >
        Ace the Data Science Interview
      </AppLink>
    </Text>
  </Flex>
);

export default Heading;
