import { FC } from 'react';
import styles from './AboutSection.module.scss';

export const GoogleNamePainted: FC = () => (
  <span>
    {'Google'.split('').map((letter, index) => (
      <b className={styles.google} key={index}>
        {letter}
      </b>
    ))}
  </span>
);
