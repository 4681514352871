import { FC } from 'react';
import Link from 'next/link';
import { Button, Flex, FlexProps, Heading, HeadingProps } from '@chakra-ui/react';
import Image from 'next/image';
import { ExternalLinks, Routes } from '@/constants';
import { GoogleNamePainted } from './GoogleNamePainted';
import styles from './AboutSection.module.scss';

interface AboutSectionProps extends FlexProps {
  titleStyleProps?: HeadingProps;
  shouldHideStartPracticingButton?: boolean;
}

const AboutSection: FC<AboutSectionProps> = (props) => {
  const { className, titleStyleProps, shouldHideStartPracticingButton = false, ...styleProps } = props;

  return (
    <Flex className={className} flexDirection="column" {...styleProps}>
      <Heading as="h2" variant="main" textAlign="center" margin="20px 0" {...titleStyleProps}>
        About DataLemur Founder Nick Singh
      </Heading>
      <Flex className={styles.about}>
        <Image src={ExternalLinks.NickWithBookImgSrc} alt="Nick Singh with book" width={400} height={512} />
        <Flex className={styles.aboutContainer}>
          <p className={styles.aboutParagraph}>
            Hi, I’m Nick Singh! Before I made DataLemur, I used to write SQL queries and run A/B tests all day at
            <b className={styles.facebook}> Facebook</b>, and before that, wrote Python on <GoogleNamePainted />{' '}
            Nest&apos;s Data Infrastructure Team. I know first-hand how difficult Data Science, Data Analyst, & Data
            Engineering interviews can be.
          </p>
          <p className={styles.aboutParagraph}>
            While my best-selling book,{' '}
            <Link
              href={ExternalLinks.BookLink}
              className={styles.aboutParagraphLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              Ace the Data Science Interview
            </Link>
            , has successfully helped 16,000+ readers prepare for the Statistics, ML, and Business-Sense portions of
            data interviews, readers kept asking for a more interactive way to practice the 201 questions from the book.
          </p>
          <p className={styles.aboutParagraph}>
            That&apos;s why I made DataLemur, an interactive SQL & Analytics interview platform for the data community!
          </p>
        </Flex>
      </Flex>
      {!shouldHideStartPracticingButton && (
        <Button alignSelf="center" my="20px" p="0" maxW="220px" colorScheme="red">
          <Link href={Routes.Questions} passHref className={styles.link}>
            Start Practicing
          </Link>
        </Button>
      )}
    </Flex>
  );
};

export default AboutSection;
